import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Project } from '@shared/models/response/project';
import { FilteredProjectModes } from '@shared/models/response/sub/project-mode';
import {
  User,
  UserCreateBaseParams,
  UserCreateBaseWithGroupParams,
} from '@shared/models/response/user';
import { UserStore } from 'app/v2/general/core/store/user.store';
import { Observable, map } from 'rxjs';

import { UserViewModel } from '../general/application/view-model/user-view-model';

export type GetProjectsOptions = {
  page?: number;
  per_page?: number;
  order?: 'asc' | 'desc';
  status?: FilteredProjectModes[];
  project_name?: string;
};

export type GetProjectsResponse = {
  projects: Project[];
  templates: Project[];
  samples: Project[];
  standard_sets: Project[];
};

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  private http = inject(HttpClient);
  private userStore = inject(UserStore);

  me(): Observable<User> {
    this.userStore.patchState({ isLoading: true });

    return this.http.get<User>('/api/users/me');
  }

  create(
    request: UserCreateBaseParams | UserCreateBaseWithGroupParams,
  ): Observable<User> {
    return this.http.post<User>('/api/admin/user', { ...request });
  }

  update(userId: number, target: UserViewModel): Observable<UserViewModel> {
    return this.http.put<UserViewModel>(`/api/v2/users/${userId}`, target);
  }

  getEnableProjectUser(userId: number): Observable<User> {
    return this.http.get<User>(`/api/users/${userId}/get-enable-project-user`);
  }

  getProjects(
    userId: number,
    options?: GetProjectsOptions,
  ): Observable<GetProjectsResponse> {
    const params = new HttpParams({
      fromObject: {
        ...options,
        status: options?.status ? options.status.join(',') : '',
      },
    });

    return this.http.get<GetProjectsResponse>(
      `/api/users/${userId}/get-projects`,
      {
        params,
      },
    );
  }

  // TODO: レスポンスの型を調査してもらう
  existsMail(mail: string): Observable<boolean> {
    return this.http
      .post<{ exists: boolean }>(`/api/users/exists`, { mail })
      .pipe(map((res) => res.exists));
  }
}
